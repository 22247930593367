/**
 * Preference store
 *
 * @author: exode <hello@exode.ru>
 */

import { observer } from 'mobx-react';
import { makeAutoObservable, observable } from 'mobx';

import { Storage } from '@/api/storage';

import { IS_NATIVE } from '@/root/src/env';
import { DocumentEvent } from '@/types/window';
import { AppColorSchemeType, NativeBridgeEvent } from '@/shared/types';

import { UiService } from '@/services/Ui/Ui';
import { PreferenceService } from '@/services/Preference/Preference';


class Preference {

    constructor() {
        makeAutoObservable(this);

        setTimeout(() => {
            const message: NativeBridgeEvent = {
                module: 'WebView',
                method: 'webViewIsReady',
            };

            /** Service isn't initialized at this moment */
            window.ReactNativeWebView?.postMessage?.(JSON.stringify(message));
        }, 500);
    }

    /**
     * Текущая цветовая схема приложения
     * Для IS_NATIVE и системной темной темы - не давать делать светлую
     * @type {AppColorSchemeType}
     */
    scheme: AppColorSchemeType = (!UiService.systemIsDark || !IS_NATIVE)
        ? this.getSavedOrSystemScheme().scheme
        : 'space_gray';

    /** Переключение выключено */
    toggleIsDisabled: boolean = false;

    /** Статус spinner */
    spinner: boolean = false;

    /** Показ конфетти */
    @observable
    confetti = {
        show: false,
        duration: 0,
        timeout: null as unknown as NodeJS.Timeout,
    };

    /** Временный цвет statusbar */
    temporaryStatusBarColor: string;

    /**
     * Получение цвета tab theme
     * @returns {string}
     */
    get tabThemeColor() {
        return this.getTabThemeColor(this.scheme);
    }

    /**
     * Определение установки тёмной темы
     * @returns {string}
     */
    get isDark() {
        return this.scheme === 'space_gray';
    }

    /**
     * Вызов показа конфетти
     * @param {{duration: number}} options
     */
    showConfetti(
        options: {
            duration: number;
        },
    ) {
        const { duration } = options;

        this.confetti.show = true;
        this.confetti.duration = duration;

        if (this.confetti.timeout) {
            clearTimeout(this.confetti.timeout);
        }

        this.confetti.timeout = setTimeout(
            () => this.confetti.show = false,
            duration,
        );
    }

    /**
     * Вычисление цвета tab theme
     * @returns {string}
     */
    getTabThemeColor(scheme: AppColorSchemeType) {
        return this.temporaryStatusBarColor || (scheme === 'bright_light' ? '#ffffff' : '#19191a');
    }

    /**
     * Переключение темы
     */
    toggleScheme() {
        const setToDark = !this.isDark;
        const scheme = setToDark ? 'space_gray' : 'bright_light';

        return this.setScheme(scheme);
    }

    /**
     * Получение сохраненной или системной темы
     * @returns {{current: 'bright_light' | 'space_gray', systemThemeIsDark: boolean}}
     */
    getSavedOrSystemScheme() {
        const systemThemeIsDark = window.matchMedia
            && window.matchMedia('(prefers-color-scheme: dark)').matches;

        const scheme = Storage.get(
            'preference:scheme',
            systemThemeIsDark ? 'space_gray' : 'bright_light',
        ) as AppColorSchemeType;

        return { scheme, systemThemeIsDark };
    }

    /**
     * Установка темы
     * @param {AppColorSchemeType} scheme
     * @param {boolean} saveToLs
     * @param triggerEvent
     */
    async setScheme(
        scheme: AppColorSchemeType,
        saveToLs = true,
        triggerEvent = true,
    ) {
        saveToLs && await PreferenceService.saveScheme(scheme);

        this.scheme = scheme;

        if (triggerEvent) {
            const customEvent = new CustomEvent(
                DocumentEvent.PreferenceSchemeChanged,
                { detail: { scheme } },
            );

            document.dispatchEvent(customEvent);
        }
    }

    /**
     * Установка временного цвета tab
     * @param {string} color
     * @returns {void}
     */
    setTemporaryStatusBarColor(color: string) {
        this.temporaryStatusBarColor = color;
    }

    /**
     * Merge
     * @param {Partial<this>} partial
     */
    merge(partial: Partial<this>) {
        Object.assign(this, partial);
    }

}

const PreferenceStore = new Preference();


export { observer, PreferenceStore };
