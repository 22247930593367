/**
 * UploadButtonPart component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement, useEffect, useState } from 'react';

import { If } from '@/cutils';
import { FileInfo } from '@/hooks/core';

import { FileExtension } from '@/shared/types';

import { Spinner } from '@exode.ru/vkui';
import { Icon28AddCircleFillBlue, Icon28CancelCircleFillRed, Icon28EditCircleFillBlue } from '@vkontakte/icons';

import { FileUploadProps } from '@/components/Atoms/FileUpload';


interface Props extends FileUploadProps {
    onDelete: () => void;
    isUploading: boolean;
    uploadProgress: number;
    filesInfo: FileInfo[] | null;
    children?: ReactElement;
}


const UploadButtonPart = (props: Props) => {

    const {
        onDelete,
        children,
        filesInfo,
        isUploading,
        previewImage,
        uploadProgress,
        controlIconSize = 56,
    } = props;

    const [ isDeleting, setIsDeleting ] = useState(false);

    const commonIconProps = {
        width: controlIconSize,
        height: controlIconSize,
        className: 'vk-shadow rounded-full',
    };

    useEffect(() => {
        if (previewImage?.src) {
            setIsDeleting(false);
        }
    }, [ previewImage ]);

    if (filesInfo && filesInfo.some(({ fileExtension }) => fileExtension !== FileExtension.Docs)) {
        return (<></>);
    }

    if (!isUploading && (uploadProgress === 0 || uploadProgress === 100)) {
        return children || (
            <div className="flex gap-3">
                <If is={!previewImage?.src}>
                    <Icon28AddCircleFillBlue {...commonIconProps}/>
                </If>

                <If is={!!previewImage?.src}>
                    <If is={!isDeleting}>
                        <Icon28EditCircleFillBlue {...commonIconProps}/>

                        <Icon28CancelCircleFillRed {...commonIconProps} onClick={(e) => {
                            e.preventDefault();
                            setIsDeleting(true);

                            onDelete();
                        }}/>
                    </If>

                    <If is={isDeleting}>
                        <div className="bg-content rounded-full p-3 vk-shadow">
                            <Spinner className="text-primary"/>
                        </div>
                    </If>
                </If>
            </div>
        );
    }

    return (<></>);
};


export { UploadButtonPart };
