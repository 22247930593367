/**
 * Manage types
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { useI18n, useI18nStatic } from '@/hooks/core/useI18n';

import { GqlResult } from '@/types/graphql';

import {
    CourseFindManyLessonsQueryResult,
    CourseFindManyQueryResult,
    CourseFindOneManageQueryResult,
    CourseFindOneQueryResult,
    CourseLessonStatus,
    CourseProgressFindManyCurrentQueryResult,
    CourseProgressLessonStatus,
    CourseProgressType,
    CourseType,
    StoriesStatus,
    StoriesType,
} from '@/codegen/graphql';

import {
    Icon16Flag,
    Icon20ArchiveOutline,
    Icon20CalendarOutline,
    Icon20CheckCircleOutline,
    Icon20ChevronCircleOutline,
    Icon20LockOutline,
    Icon20RecentOutline,
    Icon24HideOutline,
    Icon24HistoryBackwardOutline,
    Icon24ViewOutline,
    Icon28BookSpreadOutline,
    Icon28LogoVkVideoOutline,
    Icon28UsersOutline,
    Icon28UserTagOutline,
    Icon28VideocamOutline,
} from '@vkontakte/icons';


export type CourseTabType = 'lesson' | 'practice';
export type CourseFindOne = GqlResult<CourseFindOneQueryResult>['courseFindOne'];
export type CourseFindOneManage = GqlResult<CourseFindOneManageQueryResult>['courseFindOne'];
export type CourseLessonList = GqlResult<CourseFindManyLessonsQueryResult>['courseLessonFindMany']['items'];
export type CourseFindManyItem = NonNullable<GqlResult<CourseFindManyQueryResult>['courseFindMany']['items']>[number];
export type CurrentLesson = NonNullable<CourseFindOne['currentLesson']>;
export type CourseLessonItem = NonNullable<CourseLessonList>[number];
export type CourseLessonTreeItem = CourseLessonItem & { items?: CourseLessonItem[]; }
export type CourseLessonItems = NonNullable<CourseFindOne['lessons']>;
export type CourseProgressCurrentItem = NonNullable<GqlResult<
    CourseProgressFindManyCurrentQueryResult
>['courseProgressFindManyCurrent']['items']>[number];


interface CourseTypeDeclension {
    single: {
        nominated: {
            [key in CourseType]: string
        }
        parental: {
            [key in CourseType]: string
        }
        dative: {
            [key in CourseType]: string
        }
        instrumental: {
            [key in CourseType]: string
        }
        prepositional: {
            [key in CourseType]: string
        }
    };

    plural: {
        nominated: {
            [key in CourseType]: string
        }
    };
}

/**
 * Все типы и склонения по типу курса
 * @type CourseTypeDeclension
 */
export const courseTypeDeclension = () => {

    const { t: tStatic } = useI18nStatic('static.types.course');

    return {
        single: {
            nominated: {
                [CourseType.VideoCourse]: tStatic('videoCourse'),
                [CourseType.TextCourse]: tStatic('textCourse'),
                [CourseType.MasterClass]: tStatic('masterClass'),
                [CourseType.Webinar]: tStatic('webinar'),
                [CourseType.PersonalLesson]: tStatic('personalLesson'),
            },
            parental: {
                [CourseType.VideoCourse]: tStatic('ofVideoCourse'),
                [CourseType.TextCourse]: tStatic('ofTextCourse'),
                [CourseType.MasterClass]: tStatic('ofMasterClass'),
                [CourseType.Webinar]: tStatic('ofWebinar'),
                [CourseType.PersonalLesson]: tStatic('ofPersonalLesson'),
            },
            dative: {
                [CourseType.VideoCourse]: tStatic('toVideoCourse'),
                [CourseType.TextCourse]: tStatic('toTextCourse'),
                [CourseType.MasterClass]: tStatic('toMasterClass'),
                [CourseType.Webinar]: tStatic('toWebinar'),
                [CourseType.PersonalLesson]: tStatic('toPersonalLesson'),
            },
            instrumental: {
                [CourseType.VideoCourse]: tStatic('withVideoCourse'),
                [CourseType.TextCourse]: tStatic('withTextCourse'),
                [CourseType.MasterClass]: tStatic('withMasterClass'),
                [CourseType.Webinar]: tStatic('withWebinar'),
                [CourseType.PersonalLesson]: tStatic('withPersonalLesson'),
            },
            prepositional: {
                [CourseType.VideoCourse]: tStatic('inVideoCourse'),
                [CourseType.TextCourse]: tStatic('inTextCourse'),
                [CourseType.MasterClass]: tStatic('inMasterClass'),
                [CourseType.Webinar]: tStatic('inWebinar'),
                [CourseType.PersonalLesson]: tStatic('inPersonalLesson'),
            },
        },
        plural: {
            nominated: {
                [CourseType.VideoCourse]: tStatic('videoCourses'),
                [CourseType.TextCourse]: tStatic('textCourses'),
                [CourseType.MasterClass]: tStatic('masterClasses'),
                [CourseType.Webinar]: tStatic('webinars'),
                [CourseType.PersonalLesson]: tStatic('personalLessons'),
            },
        },
    };
};

/**
 * Отсортированные типы курсов
 * @type {any[]}
 */
export const orderedCourseTypes = [
    CourseType.VideoCourse,
    CourseType.PersonalLesson,
    CourseType.TextCourse,
    CourseType.Webinar,
];

/**
 * Заголовки типов курсов
 */
export const courseTypeSubtitle = () => {

    const { t } = useI18n('types.course');

    return {
        [CourseType.VideoCourse]: t('videoFormatLessons'),
        [CourseType.TextCourse]: t('textFormatLessons'),
        [CourseType.MasterClass]: t('expertClasses&LifeHacks'),
        [CourseType.Webinar]: t('groupOnlineClasses'),
        [CourseType.PersonalLesson]: t('individualApproach'),
    } as const;
};

/**
 * Описание типов курсов
 */
export const courseTypeDescription = () => {

    const { t } = useI18n('types.course');

    return {
        [CourseType.VideoCourse]: t('creating&ManagingVideoLessons'),
        [CourseType.TextCourse]: t('creating&ManagingTextLessons'),
        [CourseType.MasterClass]: t('interactiveLessonFormat'),
        [CourseType.Webinar]: t('videConferenceInLive'),
        [CourseType.PersonalLesson]: t('personalLessonsDirectedToIndividualWork'),
    } as const;
};


/**
 * Иконки типов курса
 */
export const courseTypeIcons: { [type in CourseType]: ReactElement } = {
    [CourseType.VideoCourse]: <Icon28LogoVkVideoOutline/>,
    [CourseType.PersonalLesson]: <Icon28UsersOutline/>,
    [CourseType.Webinar]: <Icon28VideocamOutline/>,
    [CourseType.TextCourse]: <Icon28BookSpreadOutline/>,
    [CourseType.MasterClass]: <Icon28UserTagOutline/>,
};

/**
 * Иконка в зависимости от прогресса урока в курсе
 */
export const lessonProgressStatusIcon: { [status in CourseProgressLessonStatus]: ReactElement } = {
    [CourseProgressLessonStatus.NotStarted]:
        <Icon16Flag width={24} height={24} fill="var(--accent)"/>,
    [CourseProgressLessonStatus.OnTheory]:
        <Icon16Flag width={24} height={24} fill="var(--accent)"/>,
    [CourseProgressLessonStatus.OnPractice]:
        <Icon16Flag width={24} height={24} fill="var(--accent)"/>,
    [CourseProgressLessonStatus.OnReview]:
        <Icon16Flag width={24} height={24} fill="var(--accent)"/>,
    [CourseProgressLessonStatus.OnCorrection]:
        <Icon16Flag width={24} height={24} fill="var(--accent)"/>,
    [CourseProgressLessonStatus.Completed]:
        <Icon20CheckCircleOutline width={24} height={24} fill="var(--dynamic_green)"/>,
    [CourseProgressLessonStatus.FeatureAvailable]:
        <Icon20ChevronCircleOutline width={24} height={24} fill="var(--accent)"/>,
    [CourseProgressLessonStatus.FeatureDisabled]:
        <Icon20LockOutline width={24} height={24} fill="var(--dynamic_gray)"/>,
    [CourseProgressLessonStatus.NotParticipant]:
        <Icon20LockOutline width={24} height={24} fill="var(--dynamic_gray)"/>,
    [CourseProgressLessonStatus.AvailableAsDemo]:
        <Icon20ChevronCircleOutline width={24} height={24} fill="var(--accent)"/>,
    [CourseProgressLessonStatus.CourseStartScheduled]:
        <Icon20CalendarOutline width={24} height={24} fill="var(--accent)"/>,
    [CourseProgressLessonStatus.LessonStartScheduled]:
        <Icon20RecentOutline width={24} height={24} fill="var(--accent)"/>,
    [CourseProgressLessonStatus.EducationCompleted]:
        <Icon20ArchiveOutline width={24} height={24} fill="var(--accent)"/>,
};

/**
 * Статус урока
 */
export const courseLessonStatus = () => {

    const { t } = useI18n('types.course');

    return {
        [CourseLessonStatus.Draft]: t('draft'),
        [CourseLessonStatus.OnCheck]: t('onCheck'),
        [CourseLessonStatus.ReadyToPublish]: t('readyToPublish'),
        [CourseLessonStatus.Published]: t('published'),
    } as const;
};

/**
 * Статус истории
 */
export const storiesStatus = () => {

    const { t } = useI18n('types.course');

    return {
        [StoriesStatus.Draft]: t('draft'),
        [StoriesStatus.OnCheck]: t('onCheck'),
        [StoriesStatus.Published]: t('storiesPublished'),
        [StoriesStatus.Declined]: t('storyRejected'),
    } as const;
};

/**
 * Иконка статуса урока
 */
export const manageCourseLessonStatusIcon: { [type in CourseLessonStatus]?: ReactElement } = {
    [CourseLessonStatus.Draft]:
        <Icon24HideOutline fill="var(--icon_secondary)"/>,
    [CourseLessonStatus.ReadyToPublish]:
        <Icon24HistoryBackwardOutline fill="var(--accent)"/>,
    [CourseLessonStatus.Published]:
        <Icon24ViewOutline fill="var(--dynamic_green)"/>,
};

/**
 * Типы историй
 */
export const storiesType = () => {

    const { t } = useI18n('types.course');

    return {
        [StoriesType.Image]: t('images'),
        [StoriesType.Video]: t('video'),
    } as const;
};

/**
 * Порядок прохождения курса
 */
export const courseProgressType = () => {

    const { t } = useI18n('types.course');

    return {
        [CourseProgressType.Free]: t('free'),
        [CourseProgressType.OnComplete]: t('onComplete'),
        [CourseProgressType.AfterCheck]: t('afterCheck'),
    } as const;
};
