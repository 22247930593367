/**
 * ShortFormView
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { Formik } from 'formik';
import { createValidator } from 'class-validator-formik';

import { observer, PlatformStore } from '@/store/platform';

import { useBecomePartner } from '@/hooks/apollo';
import { getTranslated, useI18n } from '@/hooks/core';
import { AutoSaveForm, Field, If, Link } from '@/cutils';

import { SellerOrganizationForm, SellerType } from '@/codegen/graphql';
import { sellerOrganizationForm, sellerType, sellerTypeOrganizationType } from '@/types/seller';

import { Caption } from '@exode.ru/vkui/tokenized';
import { Button, FormItem, Input, SegmentedControl, Select, Text } from '@exode.ru/vkui';

import { GradientBanner } from '@/components/Atoms/GradientBanner';

import { ShortFormSellerBecomeRequestInput } from '@/libs/class-validator/seller';


interface Props {
    mode?: 'page' | 'modal';
}


const ShortFormView = observer((props: Props) => {

    const { mode } = props;

    const { legalDocs } = PlatformStore;

    const sellerTypes = sellerType();

    const { t } = useI18n('pages.Manage.Seller.BalancePartnerShort');

    const {
        upsertShortFormBecomePartner,
        upsertShortFormBecomePartnerLoading,
    } = useBecomePartner();

    const initialValues = {
        type: SellerType.Tutor,
        organizationForm: SellerOrganizationForm.SelfEmployed,
        organizationName: '',
        contactInfo: '',
    };

    const isOrganization = (organizationForm: SellerOrganizationForm) => (
        organizationForm !== SellerOrganizationForm.SelfEmployed
    );

    const organizationNamePlaceholder = (organizationForm: SellerOrganizationForm) => (
        isOrganization(organizationForm)
            ? t('organizationName')
            : t('selfEmployedFullName')
    );

    const handleSubmit = async (values: ShortFormSellerBecomeRequestInput) => {
        await upsertShortFormBecomePartner({
            variables: {
                request: { ...values },
            },
        });
    };

    return (
        <>
            <If is={mode === 'page'}>
                <Text className="text-secondary px-4 my-4" weight="regular">
                    {t('afterSuccessfullyBecomingPartner')}
                </Text>
            </If>

            <Formik enableReinitialize
                    validateOnBlur
                    validateOnChange
                    validateOnMount
                    initialValues={initialValues}
                    onSubmit={(values) => handleSubmit(values)}
                    validate={createValidator(ShortFormSellerBecomeRequestInput)}>
                {({
                      values,
                      errors,
                      handleBlur,
                      touched,
                      isSubmitting,
                      handleChange,
                      setFieldValue,
                      isValid,
                      handleSubmit,
                  }) => (
                    <AutoSaveForm onlyNamedFields onSubmit={handleSubmit} className="flex flex-col flex-1">
                        <FormItem top={t('areYouTutorOrEducationalCenter')}
                                  className="py-0"
                                  status={Field.status(errors, touched, 'type')}
                                  bottom={Field.message(errors, touched, 'type')}>
                            <SegmentedControl name="type"
                                              className="thin-border"
                                              value={values.type || SellerType.Tutor}
                                              onChange={(value) => {
                                                  setFieldValue('type', value);
                                                  setFieldValue('organizationForm', sellerTypeOrganizationType[value as SellerType][0]);
                                              }}
                                              options={[
                                                  {
                                                      label: sellerTypes[SellerType.Tutor],
                                                      value: SellerType.Tutor,
                                                  },
                                                  {
                                                      label: sellerTypes[SellerType.Producer],
                                                      value: SellerType.Producer,
                                                  },
                                              ]}/>
                        </FormItem>

                        <FormItem className="py-0"
                                  top={t('formOfYouOrganization')}
                                  status={Field.status(errors, touched, 'organizationForm')}
                                  bottom={Field.message(errors, touched, 'organizationForm')}>
                            <Select searchable
                                    name="organizationForm"
                                    onChange={handleChange}
                                    value={values.organizationForm}
                                    onBlurCapture={handleBlur('organizationForm')}
                                    options={_.map(sellerTypeOrganizationType[values.type], value => ({
                                        value,
                                        label: sellerOrganizationForm[value],
                                    }))}/>
                        </FormItem>

                        <If is={!isOrganization(values.organizationForm)}>
                            <div className="px-4 mb-3">
                                <GradientBanner imageTheme="light"
                                                gradient="bg-content"
                                                header={t('wantToBeSelfEmployed?')}
                                                subheader={t('weWillHelpYouFillOutAsIndividual')}/>
                            </div>
                        </If>

                        <FormItem className="py-0"
                                  top={`${organizationNamePlaceholder(values.organizationForm)} *`}
                                  status={Field.status(errors, touched, 'organizationName')}
                                  bottom={Field.message(errors, touched, 'organizationName')}>
                            <Input type="text"
                                   onBlur={handleBlur}
                                   name="organizationName"
                                   value={values.organizationName}
                                   onInput={Field.transform.ignoreSpaceOnChange(handleChange)}
                                   placeholder={organizationNamePlaceholder(values.organizationForm)}/>
                        </FormItem>

                        <FormItem className="py-0"
                                  top={t('contactInAnyMessenger*')}
                                  status={Field.status(errors, touched, 'contactInfo')}
                                  bottom={Field.message(errors, touched, 'contactInfo')}>
                            <Input type="text"
                                   name="contactInfo"
                                   onBlur={handleBlur}
                                   placeholder={t('telephoneOrOrAnyMessenger')}
                                   onInput={Field.transform.ignoreSpaceOnChange(handleChange)}/>
                        </FormItem>

                        <FormItem className="py-0 mt-2">
                            <Button size="l"
                                    stretched
                                    type="submit"
                                    data-test="button.submit"
                                    loading={isSubmitting || upsertShortFormBecomePartnerLoading}
                                    disabled={(
                                        isSubmitting
                                        || !isValid
                                        || upsertShortFormBecomePartnerLoading
                                    )}>
                                {t('goToCreateCourses')}
                            </Button>
                        </FormItem>

                        <Caption level="2" weight="regular" className="text-subhead opacity-70 mt-5 mb-2 text-center">
                            Отправляя форму, вы соглашаетесь
                            с{' '}
                            <Link toOuter={getTranslated(legalDocs?.typical.PartnerAgreement)}>
                                <span className="underline">условиями</span>
                            </Link>
                        </Caption>
                    </AutoSaveForm>
                )}
            </Formik>
        </>
    );
});


export { ShortFormView };
