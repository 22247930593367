/**
 * Manage drawer
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { ReactElement } from 'react';

import { TFunction } from 'i18next';

import { IS_SCHOOL } from '@/root/src/env';

import { Routes } from '@/router/routes';
import { PageAction, Router } from '@/services/Utils/Router';

import { CourseType, Permission } from '@/codegen/graphql';
import { PageSpace, SchoolPermissions, SellerPermissions } from '@/shared/types';

import {
    Icon28CoinsOutline,
    Icon28CrownOutline,
    Icon28CubeBoxOutline,
    Icon28ListBulletSquareOutline,
    Icon28LogoInstagram,
    Icon28MessagesOutline,
    Icon28SchoolOutline,
    Icon28Users3Outline,
    Icon28UsersOutline,
    Icon28UserTagOutline,
} from '@vkontakte/icons';


export type DrawerSectionType = keyof ReturnType<typeof manageDrawerSections>;

export type SellerManagerPermissions = Array<{
    value: SellerPermissions | SchoolPermissions;
    title: string;
    description: string;
}>

export interface ManageServiceItem {
    title: string;
    description: string;
    icon: ReactElement;
    spaces?: PageSpace[];
    permissions?: Permission[];
    path?: PageAction;
    subfields?: Array<
        ManageServiceItem & { type: 'item', path: PageAction }
        | { type: 'section'; section?: string; divider?: boolean; }
    >;
}

export type ManageDrawerSections = Record<
    'commercial' | 'interactions' | 'content' | 'administration',
    {
        name: string;
        items: Record<string, ManageServiceItem>
    }
>;


export const manageDrawerSections: (
    tStatic: TFunction<string, undefined>,
) => ManageDrawerSections = (tStatic) => {
    const drawer: ManageDrawerSections = Object.freeze({
        commercial: {
            name: tStatic('commercial'),
            items: {
                products: {
                    title: tStatic('products'),
                    description: tStatic('productManagement'),
                    icon: <Icon28CubeBoxOutline/>,
                    spaces: [ PageSpace.ProductsRightMenu ],
                    permissions: Router.getContextMenuSectionPermissions('products'),
                    path: {
                        id: '/manage/courses/:page([0-9]+)',
                        params: { type: CourseType.VideoCourse },
                    },
                },
                ...IS_SCHOOL
                    ? null
                    : {
                        seller: {
                            title: tStatic('seller'),
                            description: tStatic('sellerManagement'),
                            icon: <Icon28UserTagOutline/>,
                            spaces: [ PageSpace.SellerRightMenu ],
                            permissions: Router.getContextMenuSectionPermissions('seller'),
                            path: {
                                id: Router.getFirstContextMenuItem('seller')?.path?.id,
                            },
                        },
                    },
                salesAndFinances: {
                    title: IS_SCHOOL ? tStatic('sales') : tStatic('finance'),
                    description: tStatic('purchases&WithdrawalOfFunds'),
                    permissions: Router.getContextMenuSectionPermissions('sales'),
                    icon: <Icon28CoinsOutline/>,
                    spaces: [ PageSpace.FinancesRightMenu ],
                    path: {
                        id: Router.getFirstContextMenuItem('sales')?.path?.id,
                    },
                },
            },
        },
        interactions: {
            name: tStatic('interaction'),
            items: {
                questions: {
                    title: tStatic('practice'),
                    description: tStatic('questions&AnswersFromStudents'),
                    icon: <Icon28MessagesOutline/>,
                    spaces: [ PageSpace.PracticeRightMenu ],
                    path: {
                        id: '/manage/practice',
                        params: { folder: 'Practice' },
                    },
                },
                users: {
                    title: tStatic('schoolUsers'),
                    description: tStatic('manageSchoolUsers'),
                    icon: <Icon28UsersOutline/>,
                    spaces: [ PageSpace.UsersRightMenu ],
                    path: { id: '/manage/school/users/:page([0-9]+)' },
                },
                students: {
                    title: tStatic('myStudents'),
                    description: tStatic('studentManagement'),
                    icon: <Icon28Users3Outline/>,
                    spaces: [ PageSpace.StudentsRightMenu ],
                    path: { id: '/manage/students/:page([0-9]+)' },
                },
            },
        },
        content: {
            name: tStatic('content'),
            items: {
                ...IS_SCHOOL
                    ? {
                        promotion: {
                            title: tStatic('stories'),
                            icon: <Icon28LogoInstagram/>,
                            description: tStatic('createTournamentsWithOwnTasks'),
                            spaces: [ PageSpace.PromotionRightMenu ],
                            path: { id: '/manage/stories' },
                        },
                    }
                    : {
                        exercises: {
                            title: tStatic('taskBase'),
                            description: tStatic('taskBaseManagement'),
                            icon: <Icon28ListBulletSquareOutline/>,
                            spaces: [ PageSpace.TaskBaseRightMenu ],
                            path: { id: '/manage/tasks/:page([0-9]+)' },
                        },
                    },
            },
        },
        administration: {
            name: tStatic('administrate'),
            items: {
                admin: {
                    title: tStatic('admin'),
                    description: tStatic('adminManagement'),
                    icon: <Icon28CrownOutline/>,
                    spaces: [ PageSpace.AdminRightMenu ],
                    path: { id: '/admin/users/:page([0-9]+)' },
                },
                ...IS_SCHOOL
                    ? {
                        school: {
                            title: tStatic('mySchool'),
                            description: tStatic('schoolProfileManagement'),
                            permissions: Router.getContextMenuSectionPermissions('school'),
                            icon: <Icon28SchoolOutline/>,
                            spaces: [ PageSpace.SchoolRightMenu ],
                            path: { id: Router.getFirstContextMenuItem('school')?.path?.id },
                        },
                    } : null,
            },
        },
    });

    for (const [ __, section ] of _.entries(drawer)) {
        for (const [ __, item ] of _.entries(section.items)) {
            if (item.path && !item.permissions) {
                item.permissions = _.flatten(Routes[item.path.id]?.auth) || [];
            }
        }

        section.items = _.pickBy(section.items, ({ path }) => {
            return !path || Router.filterByPageId(path?.id);
        });
    }

    return drawer;
};
