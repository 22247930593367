/**
 * ManageRoutes
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Permission } from '@/codegen/graphql';

import { RouterItemsType } from '@/types/router';
import { lazyWithRetry } from '@/helpers/react';
import { courseReviewer, PageSpace } from '@/shared/types';


const ManageAdminArticlePage = lazyWithRetry(() => import('@/pages/Manage/Admin/Article'));
const ManageAdminPartnerRequests = lazyWithRetry(() => import('@/pages/Manage/Admin/PartnerRequests'));
const ManageAdminUserListPage = lazyWithRetry(() => import('@/pages/Manage/Admin/UserList'));
const ManageAdminUserPage = lazyWithRetry(() => import('@/pages/Manage/Admin/User'));

const ManageCourseCertificatesPage = lazyWithRetry(() => import('@/pages/Manage/Courses/Certificates'));
const ManageCourseCommentsPage = lazyWithRetry(() => import('@/pages/Manage/Courses/Comments'));
const ManageCourseContentPage = lazyWithRetry(() => import('@/pages/Manage/Courses/Content'));
const ManageCourseDiscountsPage = lazyWithRetry(() => import('@/pages/Manage/Courses/Discounts'));
const ManageCourseInformationPage = lazyWithRetry(() => import('@/pages/Manage/Courses/Information'));
const ManageCourseLaunchesPage = lazyWithRetry(() => import('@/pages/Manage/Courses/Launches'));
const ManageCourseLessonPage = lazyWithRetry(() => import('@/pages/Manage/Courses/Lesson'));
const ManageCoursePublicationPage = lazyWithRetry(() => import('@/pages/Manage/Courses/Publication'));
const ManageCoursesFaqPage = lazyWithRetry(() => import('@/pages/Manage/Courses/Faq'));
const ManageCoursesListPage = lazyWithRetry(() => import('@/pages/Manage/Courses/List'));

const ManageFinancesPage = lazyWithRetry(() => import('@/pages/Manage/Finances'));
const ManageInvoicesPage = lazyWithRetry(() => import('@/pages/Manage/Invoices'));
const ManagePaymentsPage = lazyWithRetry(() => import('@/pages/Manage/Payments'));
const ManagePayoutPage = lazyWithRetry(() => import('@/pages/Manage/Payout'));
const ManageRefundsPage = lazyWithRetry(() => import('@/pages/Manage/Refunds'));
const ManageSellerBalancePage = lazyWithRetry(() => import('@/pages/Manage/Seller/Balance'));

const ManageSchoolAcquiringPage = lazyWithRetry(() => import('@/pages/Manage/School/Acquiring'));
const ManageSchoolAnalyticsPage = lazyWithRetry(() => import('@/pages/Manage/School/Analytics'));
const ManageSchoolDisciplinesPage = lazyWithRetry(() => import('@/pages/Manage/School/Disciplines'));
const ManageSchoolLegalPage = lazyWithRetry(() => import('@/pages/Manage/School/Legal'));
const ManageSchoolNotificationsPage = lazyWithRetry(() => import('@/pages/Manage/School/Notifications'));
const ManageSchoolPrivacyPage = lazyWithRetry(() => import('@/pages/Manage/School/Privacy'));
const ManageSchoolSettingsPage = lazyWithRetry(() => import('@/pages/Manage/School/Settings'));
const ManageSchoolSubscriptionPage = lazyWithRetry(() => import('@/pages/Manage/School/Subscription'));
const ManageSchoolUsersPage = lazyWithRetry(() => import('@/pages/Manage/School/Users'));
const ManageSchoolsListPage = lazyWithRetry(() => import('@/pages/Manage/School/SchoolsList'));

const ManageSellerManagerPage = lazyWithRetry(() => import('@/pages/Manage/Seller/Manager'));
const ManageSellerManagersPage = lazyWithRetry(() => import('@/pages/Manage/Seller/Managers'));
const ManageSellerPage = lazyWithRetry(() => import('@/pages/Manage/Seller/Seller'));

const ManageServiceListPage = lazyWithRetry(() => import('@/pages/Manage/ServiceList'));
const ManageStoriesPage = lazyWithRetry(() => import('@/pages/Manage/Stories'));
const ManageStudentsPage = lazyWithRetry(() => import('@/pages/Manage/Students'));

const ManagePracticePage = lazyWithRetry(() => import('@/pages/Manage/Practice'));
const ManageTaskCreatePage = lazyWithRetry(() => import('@/pages/Manage/Tasks/Create'));
const ManageTasksListPage = lazyWithRetry(() => import('@/pages/Manage/Tasks/List'));
const ManageTournamentCreatePage = lazyWithRetry(() => import('@/pages/Manage/Tournaments/Create'));


const ManageRoutes: RouterItemsType = {
    /** Admin space */
    '/admin/users/:page([0-9]+)': {
        view: <ManageAdminUserListPage/>,
        auth: [
            Permission.UserIsAdmin,
        ],
        spaces: [
            PageSpace.MarketplacePlatform,
            PageSpace.AdminRightMenu,
        ],
    },
    '/admin/users/:page([0-9]+)/:userId([0-9]+)/permissions': {
        view: <ManageAdminUserPage/>,
        auth: [
            Permission.UserIsAdmin,
        ],
        spaces: [
            PageSpace.MarketplacePlatform,
        ],
    },
    '/admin/users/:page([0-9]+)/:userId([0-9]+)/info': {
        view: <ManageAdminUserPage/>,
        auth: [
            Permission.UserIsAdmin,
        ],
        spaces: [
            PageSpace.MarketplacePlatform,
        ],
    },
    '/admin/users/:page([0-9]+)/:userId([0-9]+)/statistics': {
        view: <ManageAdminUserPage/>,
        auth: [
            Permission.UserIsAdmin,
        ],
        spaces: [
            PageSpace.MarketplacePlatform,
        ],
    },
    '/admin/partner-requests/:page([0-9]+)': {
        view: <ManageAdminPartnerRequests/>,
        auth: [
            Permission.UserIsAdmin,
            Permission.AdminSellerVerify,
        ],
        spaces: [
            PageSpace.MarketplacePlatform,
        ],
    },
    '/admin/partner-requests/:page([0-9]+)/:requestId([0-9]+)': {
        view: <ManageAdminPartnerRequests/>,
        auth: [
            Permission.AdminSellerVerify,
        ],
        spaces: [
            PageSpace.MarketplacePlatform,
        ],
    },
    '/admin/article/:articleId([0-9]+)': {
        view: <ManageAdminArticlePage/>,
        auth: [ Permission.UserIsAdmin ],
        spaces: [
            PageSpace.MarketplacePlatform,
        ],
    },
    '/admin/articles/:page([0-9]+)': {
        view: <ManageAdminArticlePage/>,
        auth: [ Permission.UserIsAdmin ],
        spaces: [
            PageSpace.MarketplacePlatform,
        ],
    },

    /** Partner (Seller) space */
    '/manage': {
        view: <ManageServiceListPage/>,
        auth: [],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/manage/courses/:page([0-9]+)': {
        view: <ManageCoursesListPage/>,
        auth: [
            Permission.CourseManage,
            Permission.CourseView,
            Permission.ProductManage,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.ProductsRightMenu,
        ],
    },
    '/manage/course/:courseId([0-9]+)/content/:page([0-9]+)': {
        view: <ManageCourseContentPage/>,
        auth: [
            Permission.CourseManage,
            Permission.CourseView,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.ProductsRightMenu,
        ],
    },
    '/manage/course/:courseId([0-9]+)/content/:page([0-9]+)/:lessonId([0-9]+)': {
        view: <ManageCourseLessonPage/>,
        auth: [
            ...courseReviewer,
            Permission.CourseManage,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.ProductsRightMenu,
        ],
    },
    '/manage/course/:courseId([0-9]+)/content/:page([0-9]+)/:lessonId([0-9]+)/practice': {
        view: <ManageCourseLessonPage/>,
        auth: [
            ...courseReviewer,
            Permission.CourseManage,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.ProductsRightMenu,
        ],
    },
    '/manage/course/:courseId([0-9]+)/navigator': {
        view: <ManageCourseInformationPage/>,
        auth: [
            Permission.AdminCoursePublish,
            Permission.CourseCommentsManage,
            Permission.CourseCurator,
            Permission.CourseManage,
            Permission.CourseReportingView,
            Permission.CourseStudentManage,
            Permission.CourseView,
            Permission.ProductManage,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.ProductsRightMenu,
        ],
    },
    '/manage/course/:courseId([0-9]+)/information/branding': {
        view: <ManageCourseInformationPage/>,
        auth: [
            Permission.CourseManage,
            Permission.CourseView,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.ProductsRightMenu,
        ],
    },
    '/manage/course/:courseId([0-9]+)/information/details': {
        view: <ManageCourseInformationPage/>,
        auth: [
            Permission.CourseManage,
            Permission.CourseView,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.ProductsRightMenu,
        ],
    },
    '/manage/course/:courseId([0-9]+)/information/actions': {
        view: <ManageCourseInformationPage/>,
        auth: [
            Permission.CourseManage,
            Permission.CourseView,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.ProductsRightMenu,
        ],
    },
    '/manage/course/:courseId([0-9]+)/information/author': {
        view: <ManageCourseInformationPage/>,
        auth: [
            Permission.CourseManage,
            Permission.CourseView,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.ProductsRightMenu,
        ],
    },
    '/manage/course/:courseId([0-9]+)/information/curator': {
        view: <ManageCourseInformationPage/>,
        auth: [
            Permission.SellerManage,
            Permission.SellerManageManagers,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.ProductsRightMenu,
        ],
    },
    '/manage/course/:courseId([0-9]+)/information/order': {
        view: <ManageCourseInformationPage/>,
        auth: [
            Permission.CourseManage,
            Permission.CourseView,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.ProductsRightMenu,
        ],
    },
    '/manage/course/:courseId([0-9]+)/information/seo': {
        view: <ManageCourseInformationPage/>,
        auth: [
            Permission.CourseManage,
            Permission.CourseView,
        ],
        spaces: [ PageSpace.ProductsRightMenu ],
    },
    '/manage/course/:courseId([0-9]+)/launches/:page([0-9]+)': {
        view: <ManageCourseLaunchesPage/>,
        auth: [
            Permission.ProductManage,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.ProductsRightMenu,
        ],
    },
    '/manage/course/:courseId([0-9]+)/launches/:page([0-9]+)/:launchId([0-9]+)/settings': {
        view: <ManageCourseLaunchesPage/>,
        auth: [
            Permission.ProductManage,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.ProductsRightMenu,
        ],
    },
    '/manage/course/:courseId([0-9]+)/launches/:page([0-9]+)/:launchId([0-9]+)/publication': {
        view: <ManageCoursePublicationPage/>,
        auth: [
            Permission.ProductManage,
        ],
        spaces: [
            PageSpace.MarketplacePlatform,
            PageSpace.ProductsRightMenu,
        ],
    },
    '/manage/course/:courseId([0-9]+)/discounts/:page([0-9]+)': {
        view: <ManageCourseDiscountsPage/>,
        auth: [
            Permission.ProductManage,
            Permission.UserIsAdmin,
            Permission.AdminCoursePublish,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.ProductsRightMenu,
        ],
    },
    '/manage/course/:courseId([0-9]+)/faq': {
        view: <ManageCoursesFaqPage/>,
        auth: [
            Permission.ProductManage,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.ProductsRightMenu,
        ],
    },
    '/manage/course/:courseId([0-9]+)/faq/:faqId([0-9]+)': {
        view: <ManageCoursesFaqPage/>,
        auth: [
            Permission.ProductManage,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.ProductsRightMenu,
        ],
    },
    '/manage/course/:courseId([0-9]+)/certificates': {
        view: <ManageCourseCertificatesPage/>,
        auth: [
            Permission.ProductManage,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.ProductsRightMenu,
        ],
    },
    '/manage/course/:courseId([0-9]+)/comments': {
        view: <ManageCourseCommentsPage/>,
        auth: [
            Permission.CourseCommentsManage,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.ProductsRightMenu,
        ],
    },

    '/manage/practice': {
        view: <ManagePracticePage/>,
        auth: [
            Permission.CourseCurator,
            Permission.CourseStudentManage,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.PracticeRightMenu,
        ],
    },
    '/manage/students/:page([0-9]+)': {
        view: <ManageStudentsPage/>,
        auth: [
            Permission.CourseStudentManage,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.StudentsRightMenu,
        ],
    },
    '/manage/students/:page([0-9]+)/:courseId([0-9]+)': {
        view: <ManageStudentsPage/>,
        auth: [
            Permission.CourseStudentManage,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.ProductsRightMenu,
        ],
    },
    '/manage/students/:page([0-9]+)/:courseId([0-9]+)/:launchId([0-9]+)': {
        view: <ManageStudentsPage/>,
        auth: [
            Permission.CourseStudentManage,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.ProductsRightMenu,
        ],
    },
    '/manage/students/:page([0-9]+)/student/:accessId': {
        view: <ManageStudentsPage/>,
        auth: [
            Permission.CourseStudentManage,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.StudentsRightMenu,
        ],
    },
    '/manage/seller': {
        view: <ManageSellerPage/>,
        auth: [
            Permission.SellerManage,
        ],
        spaces: [
            PageSpace.MarketplacePlatform,
            PageSpace.SellerRightMenu,
        ],
    },
    '/manage/seller/documents': {
        view: <ManageSellerPage/>,
        auth: [
            Permission.SellerManage,
        ],
        spaces: [
            PageSpace.MarketplacePlatform,
            PageSpace.SellerRightMenu,
        ],
    },
    '/manage/seller/become-request/:requestId([0-9]+)': {
        view: <ManageSellerPage/>,
        auth: [
            Permission.SellerManage,
        ],
        spaces: [
            PageSpace.MarketplacePlatform,
        ],
    },
    '/manage/seller/balance/:page([0-9]+)': {
        view: <ManageSellerBalancePage/>,
        auth: [
            Permission.SellerFinances,
        ],
        spaces: [
            PageSpace.MarketplacePlatform,
            PageSpace.FinancesRightMenu,
        ],
    },
    '/manage/seller/product-managers/add': {
        view: <ManageSellerManagersPage/>,
        auth: [
            Permission.SellerManageManagers,
        ],
        spaces: [
            PageSpace.SellerRightMenu,
        ],
    },
    '/manage/seller/product-managers/:page([0-9]+)': {
        view: <ManageSellerManagersPage/>,
        auth: [
            Permission.SellerManageManagers,
        ],
        spaces: [
            PageSpace.MarketplacePlatform,
            PageSpace.SellerRightMenu,
        ],
    },
    '/manage/seller/managers/:page([0-9]+)': {
        view: <ManageSellerManagersPage/>,
        auth: [
            Permission.SellerManageManagers,
        ],
        spaces: [
            PageSpace.MarketplacePlatform,
            PageSpace.SellerRightMenu,
        ],
    },
    '/manage/seller/managers/add': {
        view: <ManageSellerManagersPage/>,
        auth: [
            Permission.SellerManageManagers,
        ],
        spaces: [
            PageSpace.MarketplacePlatform,
            PageSpace.SellerRightMenu,
        ],
    },
    '/manage/seller/managers/:page([0-9]+)/:managerId([0-9]+)/permissions': {
        view: <ManageSellerManagerPage/>,
        auth: [
            Permission.SellerManageManagers,
        ],
        spaces: [
            PageSpace.SellerRightMenu,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/manage/seller/product-managers/:page([0-9]+)/:productManagerId([0-9]+)/permissions': {
        view: <ManageSellerManagerPage/>,
        auth: [
            Permission.SellerManageManagers,
        ],
        spaces: [
            PageSpace.MarketplacePlatform,
            PageSpace.SellerRightMenu,
        ],
    },
    '/manage/seller/managers/:page([0-9]+)/:managerId([0-9]+)/info': {
        view: <ManageSellerManagerPage/>,
        auth: [
            Permission.SellerManageManagers,
        ],
        spaces: [
            PageSpace.MarketplacePlatform,
            PageSpace.SellerRightMenu,
        ],
    },
    '/manage/seller/managers/:page([0-9]+)/:managerId([0-9]+)/statistics': {
        view: <ManageSellerManagerPage/>,
        auth: [
            Permission.SellerManageManagers,
        ],
        spaces: [
            PageSpace.MarketplacePlatform,
            PageSpace.SellerRightMenu,
        ],
    },
    '/manage/payouts/:page([0-9]+)': {
        view: <ManagePayoutPage/>,
        auth: [
            Permission.SellerFinances,
        ],
        spaces: [
            PageSpace.MarketplacePlatform,
            PageSpace.FinancesRightMenu,
        ],
    },
    '/manage/invoices/:page([0-9]+)': {
        view: <ManageInvoicesPage/>,
        auth: [
            Permission.SellerSales,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.FinancesRightMenu,
        ],
    },
    '/manage/invoices/:page([0-9]+)/:invoiceUuid': {
        view: <ManageInvoicesPage/>,
        auth: [
            Permission.SellerSales,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.FinancesRightMenu,
        ],
    },
    '/manage/payments/:page([0-9]+)': {
        view: <ManagePaymentsPage/>,
        auth: [
            Permission.SellerSales,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.FinancesRightMenu,
        ],
    },
    '/manage/refunds/:page([0-9]+)': {
        view: <ManageRefundsPage/>,
        auth: [
            Permission.SellerSales,
            Permission.SellerRefunds,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.FinancesRightMenu,
        ],
    },
    '/manage/reports/sales': {
        view: <ManageFinancesPage/>,
        auth: [
            Permission.SellerFinances,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.FinancesRightMenu,
        ],
    },
    '/manage/tournament/create': {
        view: <ManageTournamentCreatePage/>,
        auth: [
            Permission.TournamentCreateOfficial,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.PromotionRightMenu,
        ],
    },
    '/manage/school/settings/basic': {
        view: <ManageSchoolSettingsPage/>,
        auth: [
            Permission.SchoolManageSettings,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.SchoolRightMenu,
        ],
    },
    '/manage/school/settings/preferences': {
        view: <ManageSchoolSettingsPage/>,
        auth: [
            Permission.SchoolCustomizeSite,
            Permission.SchoolManageSettings,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.SchoolRightMenu,
        ],
    },
    '/manage/school/settings/blocks': {
        view: <ManageSchoolSettingsPage/>,
        auth: [
            Permission.SchoolCustomizeSite,
            Permission.SchoolManageSettings,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.SchoolRightMenu,
        ],
    },
    '/manage/school/settings/left-menu': {
        view: <ManageSchoolSettingsPage/>,
        auth: [
            Permission.SchoolCustomizeSite,
            Permission.SchoolManageSettings,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.SchoolRightMenu,
        ],
    },
    '/manage/school/settings/custom-code': {
        view: <ManageSchoolSettingsPage/>,
        auth: [
            Permission.SchoolManageSettings,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.SchoolRightMenu,
        ],
    },
    '/manage/school/acquiring': {
        view: <ManageSchoolAcquiringPage/>,
        auth: [
            Permission.SchoolManageSettings,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.SchoolRightMenu,
        ],
    },
    '/manage/school/subscription': {
        view: <ManageSchoolSubscriptionPage/>,
        type: 'iframe',
        auth: [
            Permission.SchoolManageSettings,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.SchoolRightMenu,
        ],
    },
    '/manage/school/disciplines': {
        view: <ManageSchoolDisciplinesPage/>,
        auth: [
            Permission.SchoolManageSettings,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.SchoolRightMenu,
        ],
    },
    '/manage/school/legal': {
        view: <ManageSchoolLegalPage/>,
        auth: [
            Permission.SellerManage,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.SchoolRightMenu,
        ],
    },
    '/manage/school/privacy': {
        view: <ManageSchoolPrivacyPage/>,
        auth: [
            Permission.SchoolManageSettings,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.SchoolRightMenu,
        ],
    },
    '/manage/school/analytics': {
        view: <ManageSchoolAnalyticsPage/>,
        auth: [
            Permission.SchoolManageSettings,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.SchoolRightMenu,
        ],
    },
    '/manage/school/notifications': {
        view: <ManageSchoolNotificationsPage/>,
        auth: [
            Permission.SchoolManageSettings,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.SchoolRightMenu,
        ],
    },
    '/manage/my-schools': {
        view: <ManageSchoolsListPage/>,
        type: 'iframe',
        auth: [],
        spaces: [
            PageSpace.BizWelcomePlatform,
        ],
    },
    '/manage/school/users/:page([0-9]+)': {
        view: <ManageSchoolUsersPage/>,
        auth: [
            Permission.SchoolManageUsers,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.UsersRightMenu,
        ],
    },
    '/manage/school/managers/:page([0-9]+)': {
        view: <ManageSellerManagersPage/>,
        auth: [
            Permission.SellerManageManagers,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.SchoolRightMenu,
        ],
    },
    '/manage/school/managers/:page([0-9]+)/:managerId([0-9]+)/permissions': {
        view: <ManageSellerManagerPage/>,
        auth: [
            Permission.SellerManageManagers,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.SchoolRightMenu,
        ],
    },
    '/manage/school/managers/add': {
        view: <ManageSellerManagersPage/>,
        auth: [
            Permission.SellerManageManagers,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.SchoolRightMenu,
        ],
    },
    '/manage/school/product-managers/:page([0-9]+)': {
        view: <ManageSellerManagersPage/>,
        auth: [
            Permission.SellerManageManagers,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.SchoolRightMenu,
        ],
    },
    '/manage/school/product-managers/:page([0-9]+)/:productManagerId([0-9]+)/permissions': {
        view: <ManageSellerManagerPage/>,
        auth: [
            Permission.SellerManageManagers,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.SchoolRightMenu,
        ],
    },
    '/manage/school/product-managers/add': {
        view: <ManageSellerManagersPage/>,
        auth: [
            Permission.SellerManageManagers,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.SchoolRightMenu,
        ],
    },
    '/manage/stories': {
        view: <ManageStoriesPage/>,
        auth: [
            Permission.SellerManage,
            Permission.SchoolCustomizeSite,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.PromotionRightMenu,
        ],
    },
    '/manage/stories/create': {
        view: <ManageStoriesPage/>,
        auth: [
            Permission.SellerManage,
            Permission.SchoolCustomizeSite,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.PromotionRightMenu,
        ],
    },
    '/manage/stories/:storiesId([0-9]+)': {
        view: <ManageStoriesPage/>,
        auth: [
            Permission.SellerManage,
            Permission.SchoolCustomizeSite,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.PromotionRightMenu,
        ],
    },
    '/manage/tasks/:page([0-9]+)': {
        view: <ManageTasksListPage/>,
        tab: 'education',
        type: 'tab',
        auth: [
            Permission.TaskView,
            Permission.TaskManage,
            Permission.UserIsAdmin,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.TaskBaseRightMenu,
        ],
    },
    '/manage/tasks/:page([0-9]+)/:taskId([0-9_A-Za-z-_]+)': {
        view: <ManageTaskCreatePage/>,
        tab: 'education',
        type: 'tab',
        auth: [
            Permission.TaskView,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.TaskBaseRightMenu,
        ],
    },
    '/manage/tasks/create': {
        view: <ManageTaskCreatePage/>,
        tab: 'education',
        type: 'tab',
        auth: [
            Permission.TaskManage,
        ],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.TaskBaseRightMenu,
        ],
    },
};


export { ManageRoutes };
