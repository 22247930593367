/**
 * ShortFormBecomePartnerModal
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useLayoutEffect } from 'react';

import { useI18n } from '@/hooks/core';

import { ConfigStore } from '@/store/core/config';
import { observer, UserAuthStore } from '@/store/user/auth';

import { Router } from '@/services/Utils/Router';
import { Auth, If, Link, Modal, Unauth } from '@/cutils';

import { Button, Title } from '@exode.ru/vkui';
import { Caption } from '@exode.ru/vkui/tokenized';

import {
    Icon12FireAlt,
    Icon20FavoriteCircleFillGreen,
    Icon24CubeBoxOutline,
    Icon24DoorArrowLeftOutline,
} from '@vkontakte/icons';

import { Placeholder } from '@/components/Atoms/Placeholder';
import { useSellerContext } from '@/components/App/SellerProvider';

import { ShortFormView } from '@/pages/Manage/Seller/BecomePartnerShort/views/ShortFormView';


interface Props {
    id: string;
}


const ShortFormBecomePartnerModal = observer((props: Props) => {

    const { t } = useI18n('modals.Partner.ShortFormBecomePartner');

    const { isSeller } = useSellerContext();

    useLayoutEffect(() => {
        if (UserAuthStore.isLoggedIn && !isSeller) {
            (async () => {
                if (!ConfigStore.isDesktop) {
                    await Router.replaceModalWithTimeout();

                    Router.pushPage('/become/partner');
                }
            })();
        }
    }, [
        isSeller,
        ConfigStore.isDesktop,
        UserAuthStore.isLoggedIn,
    ]);

    return (
        <Modal.Card id={props.id} subheader={t('afterSuccessfulCompletion')} header={(
            <Title className="modal-title -ml-1.5" level="2" weight="3">
                {t('becomePartner')}
            </Title>
        )}>
            <Modal.Body isScrollable>
                <Auth>
                    <If is={isSeller}>
                        <Placeholder iconSize={96}
                                     innerPadding="1em"
                                     header={t('alreadyPartner')}
                                     icon={<Icon20FavoriteCircleFillGreen className="mb-3"/>}
                                     content={<div className="mb-6">{t('letsGoToCreateWonderfulCourses')}</div>}/>

                        <Link closeModal pushPage={{ id: '/manage/courses/:page([0-9]+)' }}>
                            <Button stretched size="l" before={<Icon24CubeBoxOutline/>}>
                                {t('openControlPanel')}
                            </Button>
                        </Link>

                        <Caption className="flex items-center justify-center mt-3 mb-1 gap-2 text-secondary">
                            <Icon12FireAlt/>

                            <span>{t('yourCanDoAnythingWeBelieveInYou')}</span>
                        </Caption>
                    </If>

                    <If is={!isSeller}>
                        <ShortFormView mode="modal"/>
                    </If>
                </Auth>

                <Unauth>
                    <Placeholder innerPadding="1em"
                                 header={t('needToBeRegistered')}
                                 content={<div className="mb-6">{t('continueAfterRegistration')}</div>}
                                 icon={<Icon24DoorArrowLeftOutline width={72} height={72} className="mb-3"/>}/>

                    <div className="m:px-4 m:mb-4">
                        <Button stretched size="l" onClick={() => {
                            Router.replaceToModalAfterLogin(
                                '/education',
                                'short-form-partner-become',
                            );
                        }}>
                            {t('loginInToAccount')}
                        </Button>
                    </div>
                </Unauth>
            </Modal.Body>
        </Modal.Card>
    );
});


export { ShortFormBecomePartnerModal };
