/**
 * SimpleCellTree
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { ReactElement } from 'react';

import { List } from '@exode.ru/vkui';

import { If } from '@/components/Utils';

import { Container } from './SimpleCellTree.styled';


interface Props {
    list: {
        parent: {
            id: number;
            element: ReactElement;
        };

        items?: ReactElement | ReactElement[]
    }[] | undefined | null;

    expandState?: Record<string, boolean>;
}


const SimpleCellTree = (props: Props) => {

    const { list, expandState } = props;

    return (
        <Container>
            {list?.map(({ parent, items }) => (
                <>
                    {parent.element}

                    <If is={!expandState || expandState[parent.id]}>
                        {_.isArray(items)
                            ? (
                                <If is={(items || [])?.length > 0}>
                                    <List className="items-list">
                                        {items?.map((item: ReactElement) => item)}
                                    </List>
                                </If>
                            )
                            : (
                                <If is={!!items}>
                                    <List className="items-list">
                                        {items}
                                    </List>
                                </If>
                            )}
                    </If>
                </>
            ))}
        </Container>
    );
};


export { SimpleCellTree };
