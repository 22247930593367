/**
 * ShowOnHover styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const showOnHover = () => {
    return `
    .show-on-hover {
        display: none;
    }
    
    .visible-on-hover {
        visibility: hidden;    
    }

    &:hover {
        .show-on-hover {
            display: block;
        }
        
        .hide-on-hover {
            display: none;
        }
        
        .visible-on-hover {
            visibility: visible;    
        }
    }`;
};

export const ShowOnHover = styled.div`
    ${showOnHover()};
`;
