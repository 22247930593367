/**
 * ManageStoriesPage store
 *
 * @author: exode <hello@exode.ru>
 */

import { makeObservable } from 'mobx';
import { observer, PageStore } from '@/pages/Core';


/** Перемещен в store для решения circular dependency */
export const defaultFilter = {
    manage: true,
};

export const dtoFilterTransformer = (filter: typeof defaultFilter) => filter;


class ManageStoriesPage extends PageStore {

    constructor() {
        super();
        makeObservable(this);
    }

    state = {
        list: { skip: 0, take: 15 },
        filter: dtoFilterTransformer(defaultFilter),
        sort: {},
    };

}

const ManageStoriesPageStore = new ManageStoriesPage();


export { observer, ManageStoriesPageStore };
