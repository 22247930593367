/**
 * Use open or focus tab hook
 *
 * @author: exode <hello@exode.ru>
 */

import { useCallback } from 'react';

import { useConfigContext } from '@/hooks/core/useConfig';


export interface UseOpenOrFocusTabProps {
    id: string;
    url: string;
    type: 'course' | 'lesson' | 'practice' | 'storage' | 'school';
}


const useOpenOrFocusTab = () => {
    const { state, setState } = useConfigContext();

    const openOrFocusTab = useCallback(({ url, type, id }: UseOpenOrFocusTabProps) => {

        const { openedTabs } = state.user;

        const isSameUrl = new URL(url).href === openedTabs[type][id]?.location.href;

        const newTab = isSameUrl && openedTabs[type][id] && !openedTabs[type][id]?.closed
            ? openedTabs[type][id]
            : window.open(url);

        setState((previousState) => ({
            ...previousState,
            user: {
                ...previousState.user,
                openedTabs: {
                    ...previousState.user.openedTabs,
                    [type]: {
                        ...previousState.user.openedTabs[type],
                        [id]: newTab,
                    },
                },
            },
        }));

        state.user.openedTabs[type][id]?.focus();
    }, [ state ]);

    return [ openOrFocusTab ];
};


export { useOpenOrFocusTab };
