/**
 * UseLessonManage
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { ApolloError, useApolloClient } from '@apollo/client';

import {
    CourseFindManyLessonsDocument,
    CourseFindManyLessonsQuery,
    CourseLessonAccessType,
    CourseLessonManageCreateMutationResult,
    CourseLessonManageUpdateMutationResult,
    CourseVideoType,
    CreateLessonCourseInput,
    UpdateLessonCourseInput,
    useCourseLessonManageCloneMutation,
    useCourseLessonManageCreateMutation,
    useCourseLessonManageDeleteMutation,
    useCourseLessonManageUpdateMutation,
    useCourseUpdateLessonOrderMutation,
} from '@/codegen/graphql';

import { DraggingIndexes } from '@/types/common';
import { CourseLessonList } from '@/types/course';

import { useI18n } from '@/hooks/core';
import { useStore } from '@/pages/Core';
import { GqlResult } from '@/types/graphql';
import { ManageCourseContentPageStore } from '@/pages/Manage/Courses/Content/store';

import { Notify } from '@/cutils';
import { ArrayUtil } from '@/utils';
import { useCourseManage } from '@/hooks/apollo';
import { Router } from '@/services/Utils/Router';

import { Button } from '@exode.ru/vkui';


export const useLessonManage = (
    courseId: number,
) => {

    const { t } = useI18n('hooks.apollo.course');

    const { list, filter, sort } = useStore(ManageCourseContentPageStore);

    const apolloClient = useApolloClient();

    const { triggerUpdatePreviewEvent } = useCourseManage();

    const getVariables = () => ({
        courseId,
        list: { ...list },
        filter: { ...filter },
        sort: { ...sort },
    });

    const initialLessonCreateData: CreateLessonCourseInput = {
        withPractice: false,
        scheduledPublishAt: undefined,
        name: t('thingOfANameToLesson'),
        description: t('thisLessonExplains'),
        accessType: CourseLessonAccessType.Participant,
        video: {
            location: '',
            duration: 0,
            type: CourseVideoType.Local,
        },
    };

    const [ _createLesson, {
        loading: createLessonLoading,
        error: createLessonError,
    } ] = useCourseLessonManageCreateMutation({
        onError(error) {
            console.log(error);
            Notify.vkui({
                appearance: 'error',
                message: t('errorSomethingWentWrong'),
            });
        },
        update(cache, { data }) {
            triggerUpdatePreviewEvent('course', courseId);

            const variables = getVariables();

            const cachedLessons = cache.readQuery<CourseFindManyLessonsQuery>({
                query: CourseFindManyLessonsDocument,
                variables,
            });

            if (!cachedLessons) {
                return console.warn('[Cache]: cachedLessons отсутствуют в кэше');
            }

            data && cache.writeQuery<CourseFindManyLessonsQuery>({
                query: CourseFindManyLessonsDocument,
                variables,
                data: {
                    courseLessonFindMany: {
                        __typename: 'ListLessonCourseOutput',
                        pages: cachedLessons?.courseLessonFindMany.pages ?? 1,
                        items: [ ...(cachedLessons?.courseLessonFindMany?.items ?? []), data.courseLessonManageCreate ],
                    },
                },
            });

            /** Refetch lessons count in course card */
            cache.evict({
                id: `CourseEntity:${courseId}`,
                fieldName: 'countLessons',
            });
        },
    });

    const createLesson = (
        courseId: number,
        lesson?: Partial<CreateLessonCourseInput> | null,
        onCompleted?: (
            lesson: GqlResult<CourseLessonManageCreateMutationResult>['courseLessonManageCreate'],
        ) => void,
    ) => {
        return _createLesson({
            variables: {
                courseId,
                lesson: {
                    ...initialLessonCreateData,
                    ...lesson,
                },
            },
            onCompleted: ({ courseLessonManageCreate: lesson }) => {
                onCompleted?.(lesson);
            },
        });
    };

    const [ _updateLesson, {
        loading: updateLessonLoading,
        error: updateLessonError,
    } ] = useCourseLessonManageUpdateMutation();

    const updateLesson = (
        courseId: number,
        lessonId: number,
        lesson: UpdateLessonCourseInput,
        onCompleted?: (lesson: GqlResult<CourseLessonManageUpdateMutationResult>['courseLessonManageUpdate']) => void,
        onError?: (error: ApolloError) => void,
    ) => {
        return _updateLesson({
            variables: {
                courseId,
                lessonId,
                lesson,
            },
            onCompleted: ({ courseLessonManageUpdate }) => {
                triggerUpdatePreviewEvent('course', courseId);

                onCompleted?.(courseLessonManageUpdate);
            },
            onError: (error) => {
                Notify.vkui({
                    appearance: 'error',
                    message: t('errorSomethingWentWrong'),
                });

                onError?.(error);
            },
        });
    };

    const [ deleteLesson, {
        loading: deleteLessonLoading,
        error: deleteLessonError,
    } ] = useCourseLessonManageDeleteMutation({
        onCompleted(data, options) {
            const variables = getVariables();

            const lessonId = options?.variables?.lessonId as number;

            const { courseLessonFindMany } = apolloClient.cache.readQuery<CourseFindManyLessonsQuery>({
                query: CourseFindManyLessonsDocument,
                variables,
            }) || {};

            if (data.courseLessonManageDelete && courseLessonFindMany) {
                apolloClient.cache.writeQuery<CourseFindManyLessonsQuery>({
                    query: CourseFindManyLessonsDocument,
                    variables,
                    data: {
                        courseLessonFindMany: {
                            pages: courseLessonFindMany.pages ?? 1,
                            items: (courseLessonFindMany.items ?? [])
                                .filter((item) => item.id !== lessonId),
                        },
                    },
                });
            }

            if (!data.courseLessonManageDelete) {
                Notify.vkui({
                    appearance: 'error',
                    message: t('notAbleToDeleteLesson'),
                });
            }
        },
        update: (cache) => {
            /** Refetch lessons count in course card */
            cache.evict({
                id: `CourseEntity:${courseId}`,
                fieldName: 'countLessons',
            });
        },
        onError(error) {
            console.log(error);
        },
    });

    const changeLessonAccessType = (
        item: NonNullable<CourseLessonList>[number],
        accessType: CourseLessonAccessType,
        onCompleted?: () => void,
    ) => {
        return updateLesson(
            courseId,
            item.id,
            { accessType },
            onCompleted,
        );
    };

    const changeLessonWithPractice = (
        lessonId: number,
        withPractice: boolean | undefined,
    ) => {
        return updateLesson(
            courseId,
            lessonId,
            { withPractice },
        );
    };

    const [ cloneLesson, {
        loading: cloneLessonLoading,
        error: cloneLessonError,
    } ] = useCourseLessonManageCloneMutation({
        update: (cache, { data }) => {
            const variables = getVariables();

            const cachedLessons = cache.readQuery<CourseFindManyLessonsQuery>({
                query: CourseFindManyLessonsDocument,
                variables,
            });

            data && cachedLessons && cache.writeQuery<CourseFindManyLessonsQuery>({
                query: CourseFindManyLessonsDocument,
                variables,
                data: {
                    courseLessonFindMany: {
                        pages: cachedLessons.courseLessonFindMany.pages ?? 1,
                        items: [
                            ...(cachedLessons?.courseLessonFindMany.items ?? []),
                            data.courseLessonManageClone,
                        ],
                    },
                },
            });

            /** Refetch lessons count in course card */
            cache.evict({
                id: `CourseEntity:${courseId}`,
                fieldName: 'countLessons',
            });
        },
        onCompleted: ({ courseLessonManageClone }) => {
            Notify.vkui({
                message: t('lessonCopyIsCreated'),
                appearance: 'success',
                vkuiOptions: {
                    layout: 'horizontal',
                    action: (
                        <Button mode="secondary" className="!bg-white !text-black" data-test="copied-lesson.move-to">
                            {t('moveTo')}
                        </Button>
                    ),
                    onActionClick: () => Router.pushPage(
                        '/manage/course/:courseId([0-9]+)/content/:page([0-9]+)/:lessonId([0-9]+)',
                        {
                            courseId: courseLessonManageClone.course.id.toString(),
                            lessonId: courseLessonManageClone.id.toString(),
                        },
                    ),
                },
            });
        },
        onError: error => {
            console.error(error);

            Notify.vkui({
                appearance: 'error',
                message: t('errorSomethingWentWrong'),
            });
        },
    });

    const [ reorder, {
        loading: reorderListLoading,
        error: reorderListError,
    } ] = useCourseUpdateLessonOrderMutation({
        onError(error) {
            console.log(error);

            Notify.vkui({
                appearance: 'error',
                message: t('errorSomethingWentWrong'),
            });
        },
    });

    const reorderList = async (
        indexes: DraggingIndexes,
        list: CourseLessonList,
    ) => {
        const variables = getVariables();

        if (list && !_.isEmpty(list)) {
            const items = ArrayUtil.reorderList(indexes, list);

            const { courseLessonFindMany } = apolloClient?.cache.readQuery<CourseFindManyLessonsQuery>({
                query: CourseFindManyLessonsDocument,
                variables,
            }) || {};

            courseLessonFindMany && apolloClient?.cache.writeQuery<CourseFindManyLessonsQuery>({
                query: CourseFindManyLessonsDocument,
                variables,
                data: {
                    courseLessonFindMany: {
                        items,
                        pages: courseLessonFindMany.pages ?? 1,
                    },
                },
            });

            await reorder({
                variables: {
                    courseId,
                    lessonIds: items.map(({ id }) => id),
                },
            });
        }
    };

    return {
        createLesson,
        createLessonError,
        createLessonLoading,
        updateLesson,
        updateLessonError,
        updateLessonLoading,
        deleteLesson,
        deleteLessonError,
        deleteLessonLoading,
        cloneLesson,
        cloneLessonError,
        cloneLessonLoading,
        reorderList,
        reorderListError,
        reorderListLoading,
        changeLessonAccessType,
        changeLessonWithPractice,
    };
};
