/**
 * UseCoursePractice
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { Maybe } from 'graphql/jsutils/Maybe';

import { apolloClient, getGqlCause } from '@/api/graphql';

import { useI18n } from '@/hooks/core';

import { Notify } from '@/cutils';

import { Router } from '@/services/Utils/Router';
import { CourseService } from '@/services/Course/Course';

import {
    AuthException,
    ChatFindManyDocument,
    CourseException,
    CourseLessonPracticeFindOneDocument,
    CourseLessonPracticeFindOneQuery,
    CourseProgressFindManyCurrentDocument,
    CourseProgressLessonStatus,
    CourseProgressType,
    useCourseLessonPracticeAttemptFinishAttemptMutation,
    useCourseLessonPracticeAttemptSaveTaskAnswerMutation,
    useCourseLessonUpsertChatMutation,
} from '@/codegen/graphql';


export const useCoursePractice = (
    courseId: number,
    lessonId: number,
    practiceId?: number,
) => {
    const { t } = useI18n('hooks.apollo.course');

    const [ upsertChat, { loading: upsertChatLoading } ] = useCourseLessonUpsertChatMutation({
        variables: { lessonId },
        onError: (error) => {
            const notParticipantErrors = [
                AuthException.Forbidden,
                CourseException.NotCourseParticipant,
            ];

            if (notParticipantErrors.includes(getGqlCause(error))) {
                Notify.toast(t('needToEnrollToTheCourse'));
            }
        },
        onCompleted: ({ courseProgressUpsertChat }) => {
            Router.pushPage('/chat', { chatId: `${courseProgressUpsertChat?.id}` });
        },
        update: () => {
            apolloClient.refetchQueries({
                include: [ ChatFindManyDocument ],
            });
        },
    });

    const [ _saveTaskAnswer, {
        data: answerData,
        loading: saveTaskAnswerLoading,
    } ] = useCourseLessonPracticeAttemptSaveTaskAnswerMutation({
        onError: (error) => console.error(error),
        update: (cache, { data }) => {
            data && cache.modify({
                id: `CourseLessonPracticeEntity:${practiceId}`,
                fields: {
                    activeAttempt: () => ({
                        __ref: `CourseLessonPracticeAttemptEntity:${data.courseLessonPracticeAttemptSaveTaskAnswer.id}`,
                    }),
                },
            });
        },
    });

    const saveTaskAnswer = async (
        taskUuid: string,
        answer: string[],
    ) => {
        if (!practiceId) {
            return alert('Practice id not provided');
        }

        await _saveTaskAnswer({
            variables: {
                courseId,
                practiceId,
                answer: { taskUuid, answer },
            },
        });
    };

    const [ _finishAttempt, {
        data: finishData,
        loading: finishAttemptLoading,
    } ] = useCourseLessonPracticeAttemptFinishAttemptMutation({
        onError: (error) => console.error(error),
        update: (cache, { data }) => {
            const { courseLessonPracticeAttemptFinishAttempt: attempt } = data || {};

            const cachedPractice = cache.readQuery<CourseLessonPracticeFindOneQuery>({
                query: CourseLessonPracticeFindOneDocument,
                variables: { courseId, lessonId },
            });

            attempt && cache.modify({
                id: `CourseLessonPracticeEntity:${practiceId}`,
                fields: {
                    activeAttempt: () => null,
                    lastFinishedAttempt: () => attempt,
                    attempts: () => _.uniqBy([
                        ...(cachedPractice?.courseLessonPracticeFindOne?.attempts || []),
                        attempt,
                    ], 'id'),
                },
            });

            for (const entity of [ 'CourseLessonPreview', 'CourseLessonEntity' ]) {
                cache.modify({
                    id: `${entity}:${lessonId}`,
                    fields: {
                        myProgressStatus: () => CourseProgressLessonStatus.Completed,
                        canStartNextLesson: () => true,
                    },
                });
            }

            apolloClient.refetchQueries({
                include: [
                    CourseProgressFindManyCurrentDocument,
                ],
            });
        },
    });

    const finishAttempt = async (
        course: {
            progressType: CourseProgressType,
        },
        currentLesson?: Maybe<{
            withPractice: boolean;
        }>,
    ) => {
        if (!practiceId) {
            alert('Practice id not provided');

            return;
        }

        return _finishAttempt({
            variables: {
                courseId,
                practiceId,
            },
            onCompleted: () => {
                if (CourseService.nextLessonStartManually(course, currentLesson)) {
                    Notify.toast.success(t('nextLessonWillBeUnlockedAfterPassingPractice'));
                }
            },
        });
    };

    return {
        upsertChat,
        upsertChatLoading,
        saveTaskAnswer,
        saveTaskAnswerLoading,
        finishAttempt,
        finishAttemptLoading,
        answerData,
        finishData,
    };
};
