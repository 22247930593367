/**
 * EditableTextArea
 *
 * @author: exode <hello@exode.ru>
 */

import * as yup from 'yup';

import React, { useState } from 'react';

import { Form, Formik } from 'formik';

import { Field, If } from '@/cutils';
import { ObjectUtil } from '@/utils';
import { useI18n } from '@/hooks/core';

import { isMinMax, isNotEmpty } from '@/libs/class-validator/constants';

import { FormItem, Input } from '@exode.ru/vkui';
import { Icon20Cancel, Icon20Check } from '@vkontakte/icons';


interface Props {
    placeholder: string;
    onSave: (value: string) => void;
    value: string | undefined | null;
    loading?: boolean;
    className?: string;
    onClick?: () => void;
    dataTest?: string;
}


const EditableTextArea = (props: Props) => {

    const {
        value,
        onSave,
        onClick,
        className,
        placeholder,
        dataTest = 'editable.text-area',
    } = props;

    const initialValues = { value: value || '' };

    const { t } = useI18n('components.Atoms.EditableTextArea');

    const [ mode, setMode ] = useState('text');

    return (
        <>
            <If is={mode === 'text'}>
                <span data-test={dataTest} onClick={() => setMode('input')} className={[
                    'w-full py-2 pl-2',
                    value ? '' : 'text-secondary',
                ].join(' ')}>
                    {value ? value : t('clickToChange')}
                </span>
            </If>

            <If is={mode === 'input'}>
                <Formik enableReinitialize
                        initialValues={initialValues}
                        onSubmit={({ value }) => onSave(value)}
                        validationSchema={yup.object().shape({
                            value: yup
                                .string()
                                .min(1, isMinMax(1, 'Название', 'min'))
                                .required(isNotEmpty('Название', 'пустым')),
                        })}>
                    {({ values, handleChange, handleSubmit, isValid, errors, touched }) => (
                        <Form onSubmit={handleSubmit}>
                            <FormItem className="p-0 w-full" status={Field.status(errors, touched, 'value')}>
                                <Input autoFocus
                                       name="value"
                                       value={values.value}
                                       className={className}
                                       onChange={handleChange}
                                       placeholder={placeholder}
                                       data-test={`${dataTest}.input`}
                                       onKeyDown={(event) => {
                                           if (isValid && event.key === 'Enter') {
                                               setMode('text');

                                               if (!ObjectUtil.isEqual(initialValues, values)) {
                                                   onClick?.();
                                                   setMode('text');

                                                   handleSubmit();
                                               }
                                           }
                                       }}
                                       after={(
                                           <div data-test={`${dataTest}.edit-wrapper`}
                                                className="flex items-center gap-2 pr-2">
                                               <Icon20Cancel fill="var(--accent)" onClick={() => setMode('text')}/>

                                               <Icon20Check fill="var(--accent)"
                                                            data-test={`${dataTest}.save`}
                                                            className={[
                                                                !isValid ? 'pointer-events-none' : '',
                                                            ].join(' ')}
                                                            onClick={() => {
                                                                onClick?.();
                                                                setMode('text');

                                                                handleSubmit();
                                                            }}/>
                                           </div>
                                       )}/>
                            </FormItem>
                        </Form>
                    )}
                </Formik>
            </If>
        </>
    );
};


export { EditableTextArea };
