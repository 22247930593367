/**
 * LoginAuthInput
 *
 * @author: exode <hello@exode.ru>
 */

import { IsNotEmpty, IsOptional, MinLength } from 'class-validator';

import { isMinMax, isNotEmpty } from '../constants';


export class LoginAuthInput {

    @IsNotEmpty({ message: isNotEmpty('Логин') })
    readonly login: string;

    @IsNotEmpty({ message: isNotEmpty('Пароль') })
    @MinLength(6, { message: isMinMax(6, 'Пароль', 'min') })
    readonly password: string;

    @IsOptional()
    @IsNotEmpty({ message: isNotEmpty('Код') })
    @MinLength(6, { message: isMinMax(6, 'Код', 'equal') })
    readonly otp?: string;

}
