/**
 * FooterPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { IS_SCHOOL } from '@/root/src/env';

import { observer, PlatformStore } from '@/store/platform';

import { getTranslated, useI18n } from '@/hooks/core';
import { If, Link, Platform, useRedirect } from '@/cutils';

import { Button, Footer } from '@exode.ru/vkui';
import { Icon24LifebuoyOutline } from '@vkontakte/icons';


const FooterPart = observer(() => {

    const { COMMON, legalDocs } = PlatformStore;

    const { t } = useI18n('components.Desktop.Navigation.Menu');

    const { redirect } = useRedirect();

    const supportButton = (
        <Button size="m"
                mode="secondary"
                before={<Icon24LifebuoyOutline/>}
                className="w-[197px] dark:border-transparent border-solid border-[0.12em]">
            <span>{t('needHelp')}</span>
        </Button>
    );

    return (
        <Footer className={[
            'fixed bottom-5 flex justify-items-start flex-wrap',
            'gap-1.5 my-0 mx-8 fs-12 w-[230px] text-left max-w-full break-words',
        ].join(' ')}>
            <div className="w-full flex mb-2">
                <Platform.Market>
                    <Link pushPage={{ id: '/support' }}>
                        {supportButton}
                    </Link>
                </Platform.Market>

                <Platform.School>
                    <If is={!!COMMON?.organization.info.messengerSupportUrl}>
                        <Link blank toOuter={COMMON?.organization.info.messengerSupportUrl}>
                            {supportButton}
                        </Link>
                    </If>
                </Platform.School>
            </div>

            <>
                <Platform.Market>
                    <Link pushPage={{ id: '/about' }}>
                        <span>{t('companyAbout')}</span>
                    </Link>
                </Platform.Market>

                <If is={!!getTranslated(legalDocs?.typical.PartnerAgreement)}>
                    <Link onClick={() => redirect(
                        getTranslated(legalDocs?.typical.PartnerAgreement),
                        true,
                    )}>
                        <span>{t('termsOfUse')}</span>
                    </Link>
                </If>

                <If is={!!getTranslated(legalDocs?.typical.DataPolicy)}>
                    <Link onClick={() => redirect(
                        getTranslated(legalDocs?.typical.DataPolicy),
                        true,
                    )}>
                        <span>{t('politics')}</span>
                    </Link>
                </If>
            </>

            <div>
                <Link disabled={IS_SCHOOL} pushPage={{ id: '/about' }}>
                    <span>
                        {[ '©', COMMON?.organization.info.name ].join(' ')}
                    </span>
                </Link>
            </div>
        </Footer>
    );
});


export { FooterPart };
