/**
 * SignupAuthInput
 *
 * @author: exode <hello@exode.ru>
 */

import { IsNotEmpty, IsOptional, IsString, MinLength } from 'class-validator';

import { isNotEmpty } from '../constants';


export class SignupAuthInput {

    @IsNotEmpty({ message: isNotEmpty('Логин') })
    readonly login: string;

    @IsOptional()
    @IsString()
    @IsNotEmpty({ message: isNotEmpty('Код', 'пустой') })
    @MinLength(6, { message: ' ' })
    readonly code?: string;

}
