/**
 * LanguageSetItem
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';

import { ConfigStore, observer } from '@/store/core/config';

import { Icon28HieroglyphCharacterOutline } from '@vkontakte/icons';

import { IconByLang } from '@/images/countries';

import { LanguageDropdown, LanguageDropdownProps } from '@/components/Atoms/LanguageDropdown';


interface Props {
    languageDropdownProps?: LanguageDropdownProps;
}


const LanguageSetItem = observer((props: Props) => {

    const { languageDropdownProps } = props;

    const { t } = useI18n('pages.User.Settings');

    const LanguageIcon = IconByLang[ConfigStore.language];

    return (
        <LanguageDropdown componentProps={{
            children: t('language'),
            className: 'm:!rounded-none',
            'data-test': 'settings.language',
            before: <Icon28HieroglyphCharacterOutline/>,
            indicator: <LanguageIcon width={24} height={24} className="block my-auto"/>,
            ...languageDropdownProps?.componentProps,
        }} dropdownProps={{
            offsetDistance: -220,
            placement: 'right-start',
            offsetSkidding: ConfigStore.isDesktop ? 45 : 10,
            ...languageDropdownProps?.dropdownProps,
        }}/>
    );
});


export { LanguageSetItem };
