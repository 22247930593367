/**
 * Saving
 *
 * @author: exode <hello@exode.ru>
 */

import { useEffect } from 'react';

import _ from 'lodash';

import { observer } from 'mobx-react';
import { makeAutoObservable } from 'mobx';

import { ApolloError } from '@apollo/client';


export enum SaveStoreKeys {
    Article = 'Article',
    Course = 'Course',
    Uploading = 'Uploading',
    Subjects = 'Subjects',
    CourseLaunch = 'CourseLaunch',
    School = 'School'
}

export enum SaveStoreDeepKeys {
    ArticleBlocksEdit = 'ArticleBlocksEdit',
    CourseInformation = 'CourseInformation',
    LessonsList = 'LessonsList',
    LessonEdit = 'LessonEdit',
    LessonBlocksEdit = 'LessonBlocksEdit',
    LessonPracticeEdit = 'LessonPracticeEdit',
    FaqEdit = 'FaqEdit',
    PriceEdit = 'PriceEdit',
    UserSubjects = 'UserSubjects',
    SchoolInformation = 'SchoolInformation',
    SellerOrganization = 'SellerOrganization'
}


class Saving {

    constructor() {
        makeAutoObservable(this);
    }

    /** Статус сохранения */
    saving: { [key in SaveStoreKeys]?: Record<SaveStoreDeepKeys, boolean> } = {};

    /** Редактирование  */
    editing: { [key in SaveStoreKeys]?: Record<SaveStoreDeepKeys, boolean> } = {};

    /** Статус сохранения блока */
    sectionIsSaving(field: SaveStoreKeys) {
        return _.some(this.saving[field], (item) => item);
    }

    /** Статус сохранения блока */
    sectionIsEditing(field: SaveStoreKeys) {
        return _.some(this.editing[field], (item) => item);
    }

    /** Статус сохранения элемента */
    itemIsSaving(
        field: SaveStoreKeys,
        item: SaveStoreDeepKeys,
    ) {
        return this.saving[field]?.[item];
    }

    /** Статус редактирования элемента */
    itemIsEditing(
        field: SaveStoreKeys,
        item: SaveStoreDeepKeys,
    ) {
        return this.saving[field]?.[item];
    }

    /** Установка значения сохранения */
    setSaving(
        field: SaveStoreKeys,
        item: SaveStoreDeepKeys,
        saving: boolean,
    ) {
        if (!this.saving[field]) {
            this.saving[field] = {} as Record<SaveStoreDeepKeys, boolean>;
        }

        this.saving[field]![item] = saving;

        this.setEditing(field, item, false);
    }

    /** Хук для прослушивания ошибок и установки saving */
    static listenSetSaving(
        field: SaveStoreKeys,
        item: SaveStoreDeepKeys,
        loadingDeps: boolean[],
        errors: (ApolloError | undefined | boolean)[],
        options: {
            disabled?: boolean;
        } = {},
    ) {
        useEffect(() => {
            if (!options.disabled && errors.every(e => !e)) {
                SavingStore.setSaving(
                    field,
                    item,
                    loadingDeps.some(e => e),
                );
            }
        }, [ ...loadingDeps, options.disabled ]);
    }

    /** Установка значения сохранения */
    setEditing(
        field: SaveStoreKeys,
        item: SaveStoreDeepKeys,
        editing: boolean,
    ) {
        if (!this.editing[field]) {
            this.editing[field] = {} as Record<SaveStoreDeepKeys, boolean>;
        }

        this.editing[field]![item] = editing;
    }

    /** Статус сохранения элемента без типизации (Record) */
    itemIsSavingRecord(
        field: SaveStoreKeys,
        item: string,
    ) {
        return !!this.saving[field]?.[item as SaveStoreDeepKeys];
    }

    /** Статус редактирования элемента без типизации (Record) */
    itemIsEditingRecord(
        field: SaveStoreKeys,
        item: string,
    ) {
        return !!this.editing[field]?.[item as SaveStoreDeepKeys];
    }

    /** Установка значения сохранения без типизации (Record) */
    setSavingRecord(
        field: SaveStoreKeys,
        item: string,
        saving: boolean,
    ) {
        if (!this.saving[field]) {
            this.saving[field] = {} as Record<SaveStoreDeepKeys, boolean>;
        }

        this.saving[field]![item as SaveStoreDeepKeys] = saving;
    }

    /** Установка значения редактирования без типизации (Record) */
    setEditingRecord(
        field: SaveStoreKeys,
        item: string,
        editing: boolean,
    ) {
        if (!this.editing[field]) {
            this.editing[field] = {} as Record<SaveStoreDeepKeys, boolean>;
        }

        this.editing[field]![item as SaveStoreDeepKeys] = editing;
    }

    /**
     * Установка значений в поля store
     * @param partial
     */
    merge(partial: Partial<Saving>) {
        Object.assign(this, partial);
    }

}

const SavingStore = new Saving();


export { observer, SavingStore, Saving };
