/**
 * UserRoutes
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { PageSpace } from '@/shared/types';

import { RouterItemsType } from '@/types/router';
import { lazyWithRetry } from '@/helpers/react';


const UserLoginPage = lazyWithRetry(() => import('@/pages/User/Login'));
const UserLoginAppPage = lazyWithRetry(() => import('@/pages/User/LoginApp'));
const UserMainInfoPage = lazyWithRetry(() => import('@/pages/User/MainInfo'));
const UserPasswordPage = lazyWithRetry(() => import ('@/pages/User/Password'));
const UserRecoverPage = lazyWithRetry(() => import('@/pages/User/Recover'));
const UserSettingsPage = lazyWithRetry(() => import('@/pages/User/Settings'));
const UserTwoFactorPage = lazyWithRetry(() => import ('@/pages/User/TwoFactor'));
const UserSignupPage = lazyWithRetry(() => import ('@/pages/User/Signup'));
const UserChangePasswordPage = lazyWithRetry(() => import ('@/pages/User/ChangePassword'));


const UserRoutes: RouterItemsType = {
    '/login': {
        view: <UserLoginPage/>,
        type: 'iframe',
        subType: 'tab',
        tab: 'menu',
        unauth: true,
        spaces: [],
    },
    '/login/app': {
        view: <UserLoginAppPage/>,
        spaces: [],
    },
    '/login/recover': {
        view: <UserRecoverPage/>,
        type: 'iframe',
        spaces: [],
    },
    '/login/password/change': {
        view: <UserChangePasswordPage/>,
        type: 'iframe',
        spaces: [],
    },
    '/login/email': {
        view: <UserSignupPage/>,
        type: 'iframe',
        tab: 'menu',
        unauth: true,
        spaces: [],
    },
    '/login/email/login': {
        view: <UserSignupPage/>,
        type: 'iframe',
        tab: 'menu',
        unauth: true,
        spaces: [],
    },
    '/login/email/signup': {
        view: <UserSignupPage/>,
        type: 'iframe',
        tab: 'menu',
        unauth: true,
        spaces: [],
    },
    '/settings': {
        view: <UserSettingsPage/>,
        type: 'tab',
        tab: 'menu',
        auth: [],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/settings/two-factor': {
        view: <UserTwoFactorPage/>,
        type: 'tab',
        tab: 'menu',
        auth: [],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/settings/password': {
        view: <UserPasswordPage/>,
        auth: [],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/profile/main-info': {
        view: <UserMainInfoPage/>,
        auth: [],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
};


export { UserRoutes };
